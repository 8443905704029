import React, { useEffect, useState } from "react";
import "./App.css";
import SideBar from "./components/sidebar/SideBar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Users from "./pages/users/Users";
import Chauffeur from "./pages/chaffeur/Chauffeur";
import ScheduleRides from "./pages/schedule/ScheduleRides";
import PastRides from "./pages/PastRides";
import Payments from "./pages/Payments";
import AuthPage from "./components/auth/login/AuthPage";
import NotFound from "./components/utils/NotFound";
import PrivateRoutes from "./components/utils/PrivateRoutes";
import AddUser from "./pages/users/AddUser";
import AddChauffeur from "./pages/chaffeur/AddChauffeur";
import ScheduleList from "./pages/schedule/ScheduleList";
import EditUser from "./pages/users/EditUser";
import Reports from "./pages/reports/Reports";
import SimpleMap from "./components/Map/map";
import EditChauffeur from "./pages/chaffeur/EditChauffeur";
import ViewReport from "./pages/reports/ViewReport";
import ViewRoster from "./pages/schedule/ViewRoster";
import { ToastContainer } from "react-toastify";
import ScheduleAdhoc from "./pages/chaffeur/ScheduleAdhoc";
import ScheduleRepeatRides from "./pages/schedule/repeatRide/ScheduleRepeatRides";
import Sos from "./pages/sos/sos";
import EditSos from "./pages/sos/editSos";
import ScheduleUserRides from "./pages/users/ScheduleUserRides";
import ViewUser from "./pages/users/ViewUser";
import ViewChauffeur from "./pages/chaffeur/ViewChauffeur";
import ViewSos from "./pages/sos/viewSos";
import Polyline from "./components/Map/polyline";
import OngoingRides from "./pages/ongoingRides/OngoingRides";
import TrackRide from "./pages/ongoingRides/TrackRide";

function App(): JSX.Element {
  const condition = true;
  const [isLoginPage, setIsLoginPage] = useState<boolean>(false);

  useEffect(() => {
    const checkIsLoginPage = () => {
      const currentPath = window.location.pathname;
      setIsLoginPage(currentPath === "/login");
    };

    checkIsLoginPage();

    const handleRouteChange = () => {
      checkIsLoginPage();
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return (
    <Router>
      <ToastContainer />
      <SideBar
        showSidebar={!isLoginPage}
        // setIsLoginPage={setIsLoginPage}
      >
        <Routes>
          <Route path="/login" element={<AuthPage />} />
          <Route element={<PrivateRoutes />}>
            <Route
              path="/"
              element={<Dashboard setIsLoginPage={setIsLoginPage} />}
            />
            <Route path="/users" element={<Users />} />
            <Route path="/users/add" element={<AddUser />} />
            <Route path="/users/:slug" element={<EditUser />} />
            <Route path="/viewUser/:slug" element={<ViewUser />} />
            <Route path="/chauffeur/:slug" element={<EditChauffeur />} />
            <Route path="/schedule-rides" element={<ScheduleList />} />
            <Route path="/schedule-rides/create" element={<ScheduleRides />} />
            <Route path="/chauffeur" element={<Chauffeur />} />
            <Route path="/chauffeur/add" element={<AddChauffeur />} />
            <Route path="/history" element={<PastRides />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/map" element={<SimpleMap />} />     
                 <Route path="/polyline" element={<Polyline />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/sos" element={<Sos />} />
            <Route path="/editSos/:slug" element={<EditSos />} />
            <Route path="/viewSos/:slug" element={<ViewSos />} />
            <Route path="/viewReport/:slug" element={<ViewReport />} />
            <Route path="/viewChauffeur/:slug" element={<ViewChauffeur />} />
            <Route path="/viewRoster/:slug" element={<ViewRoster />} />
            <Route path="schedule-adhoc/:slug" element={<ScheduleAdhoc />} />
            <Route path="schedule-repeat-rides/:slug" element={<ScheduleRepeatRides />} />   
            <Route path="schedule-user-rides/:slug" element={<ScheduleUserRides />} />    
            <Route path="onggoing-rides" element={<OngoingRides />} />      
            <Route path="/track/:rosterId" element={<TrackRide />} />    
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </SideBar>
    </Router>
  );
}

export default App;
