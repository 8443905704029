/** @format */

import React, { useState, useEffect } from "react";
import "../styledTable.css";
import {
  deleteData,
  deleteDataForRoster,
  fetchData,
} from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import Loader from "../../components/utils/Loader";
import ReactPaginate from "react-paginate";
import { MdOutlineRepeatOn } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { MdOutlinePreview } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";

function ScheduleList({ handleCreateRide }: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [scheduleListData, setScheduleListData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data: { selected: number }) => {
    setCurrentPage(data.selected);
  };

  const fetchDataAndUpdateState = async (page: number) => {
    try {
      setLoading(true);
      const pageSize = 10; 
      const pageNumber = page + 1; 
      const data = await fetchData(
        `admin/roster/?page=${pageNumber}&pageSize=${pageSize}`
      );
      setScheduleListData(data.rosters);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchDataAndUpdateState(currentPage);
  }, [currentPage]);

  const handleDeleteRoster = async (id: number) => {
    try {
      const payload = {
        status: "DELETED",
      };
      await deleteDataForRoster(`admin/roster/update/${id}`, payload);
      fetchDataAndUpdateState(currentPage);
      toast.success(`Roster deleted successfully`, {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Error deleting user");
    }
  };

  return (
    <>
      <Header />
      <div className="schedule-main">
        <div className="button-div">
          {/* <span className="input-container-add-user"> */}
          <input type="text" />
          {/* </span> */}

          {window.location.hostname === "www.gods-eye.in" ||
          window.location.hostname === "localhost" ? (
            <button
              className="add_button"
              onClick={() => navigate("/schedule-rides/create")}
            >
              Create Ride
            </button>
          ) : (
            <></>
          )}
        </div>
        {loading ? (
          <Loader />
        ) : (
          <table
            className="schedule-table table-no-border"
            style={{ borderSpacing: "0 1rem" }}
          >
            <thead>
              <tr className="table-head">
                <th>Sr. No</th>
                <th>Schedule </th>
                <th>Pickup</th>
                <th>Drop</th>
                <th>Rider</th>
                <th>Chauffeur</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(scheduleListData?.flattenedRosters) &&
                scheduleListData?.flattenedRosters?.map(
                  (schedule: any, index: number) => {
                    const serialNumber = index + 1 + currentPage * 10;
                    return (
                      <>
                        <tr
                          className="table-body"
                          key={index}
                          style={{ margin: "10px 0" }}
                        >
                          <td style={{ paddingBottom: "10px" }}>   {serialNumber}</td>
                          <td>{schedule?.scheduled_date}</td>
                          <td>
                            {schedule?.rider_roster_assignments?.map(
                              (location: any, locationIndex: number) => (
                                <React.Fragment key={locationIndex}>
                                  {location?.pickup_location}
                                  <br />
                                  <br />
                                </React.Fragment>
                              )
                            )}
                          </td>
                          <td>
                            {schedule?.rider_roster_assignments?.map(
                              (location: any, locationIndex: number) => (
                                <React.Fragment key={locationIndex}>
                                  {location?.drop_location}
                                  <br />
                                  <br />
                                </React.Fragment>
                              )
                            )}
                          </td>
                          <td>
                            {schedule?.rider_roster_assignments?.map(
                              (rider: any, index: number) => (
                                <React.Fragment key={index}>
                                  {rider?.name}
                                  <br />
                                  <br />
                                </React.Fragment>
                              )
                            )}
                          </td>
                          <td>
                            {schedule?.chauffeur?.name}
                            <br />
                            <br />
                          </td>
                          {window.location.hostname === "www.gods-eye.in" ||
                          window.location.hostname === "localhost" ? (
                            <>
                              <td
                                style={{  marginTop: "20px" }}
                              >
                                <>
                                  <Tooltip title="View Roster">
                                    <IconButton>
                                      <MdOutlinePreview
                                        style={{
                                          height: "35px",
                                          width: "30px",

                                          color: "grey",
                                        }}
                                        onClick={() =>
                                          navigate(
                                            `/viewRoster/${schedule?.roster_id}`
                                          )
                                        }
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Repeat/Edit Roster">
                                    <IconButton>
                                      <MdOutlineRepeatOn
                                        style={{
                                          height: "25px",
                                          width: "25px",
                                          // marginRight: "10px",
                                          color: "grey",
                                        }}
                                        onClick={() =>
                                          navigate(
                                            `/schedule-repeat-rides/${schedule?.roster_id}`
                                          )
                                        }
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete Roster">
                                    <IconButton>
                                      <MdDelete
                                        style={{
                                          height: "35px",
                                          width: "25px",
                                          marginRight: "10px",
                                          color: "grey",
                                        }}
                                        onClick={() =>
                                          handleDeleteRoster(
                                            schedule?.roster_id
                                          )
                                        }
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <br />
                                </>
                              </td>
                              <br />
                          
                            </>
                          ) : (
                            <>
                              <td>
                                <Tooltip title="View Roster">
                                  <IconButton>
                                    <MdOutlinePreview
                                      style={{
                                        height: "35px",
                                        width: "30px",

                                        color: "grey",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/viewRoster/${schedule?.roster_id}`
                                        )
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </>
                          )}
                        </tr>
                      </>
                    );
                  }
                )}
            </tbody>
          </table>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={scheduleListData?.totalRecords / 10}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
}

export default ScheduleList;
