import React, { useState, useRef } from "react";
import { GoogleMap, Marker, Polyline, LoadScript, InfoWindow } from "@react-google-maps/api";

const PolylineComponent = () => {
  const mapRef = useRef(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  // Hardcoded coordinates for chauffeur and user locations
  const chauffeurLocation = { lat: 40.7128, lng: -74.006 };
  const userLocation = { lat: 34.0522, lng: -118.2437 };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyAfQmP3pykh9oGR2CuiStJqplMZkVT1EKQ">
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        zoom={4}
        center={chauffeurLocation} // Center map around chauffeur's location
        onLoad={(map) => {
          mapRef.current = map;
        }}
      >
        {/* Chauffeur Marker */}
        <Marker
          position={chauffeurLocation}
          icon={{ url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png" }}
          onClick={() => handleMarkerClick("chauffeur")}
        />

        {/* User Marker */}
        <Marker
          position={userLocation}
          icon={{ url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" }}
          onClick={() => handleMarkerClick("user")}
        />

        {/* Polyline between chauffeur and user */}
        {selectedMarker && (
          <Polyline
            path={[chauffeurLocation, userLocation]}
            options={{ strokeColor: "#FF0000" }}
          />
        )}

        {/* InfoWindow */}
        {selectedMarker && (
          <InfoWindow
            position={selectedMarker === "chauffeur" ? chauffeurLocation : userLocation}
            onCloseClick={handleInfoWindowClose}
          >
            <div>
              <h3>{selectedMarker === "chauffeur" ? "Chauffeur" : "User"} Info</h3>
              {selectedMarker === "chauffeur" ? (
                <p>Chauffeur Location: {`${chauffeurLocation.lat}, ${chauffeurLocation.lng}`}</p>
              ) : (
                <p>User Location: {`${userLocation.lat}, ${userLocation.lng}`}</p>
              )}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default PolylineComponent;
