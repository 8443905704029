/** @format */

import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { Typography } from "antd"; 
import { Drawer } from "antd"; 
import moment from "moment";
import image from "../../assets/download (5).png";
import "antd/dist/antd.css"; 
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const OngoingRides = () => {
	const mapRef = useRef(null);
	const [apiData, setApiData] = useState([]);
	const [selectedMarker, setSelectedMarker] = useState(null);
	const [loading, setLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

	useEffect(() => {
		const fetchDataAndUpdateState = async () => {
			try {
				const token = localStorage.getItem("token_info");
				const response = await fetch(
					"https://api.gods-eye.in/api/admin/roster/getOngoingRide/all",
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				const data = await response.json();
				if (data.success) {
					setApiData(data.data || []);
				} else {
					console.error("API request failed:", data.error);
				}
				setLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
				setLoading(false);
			}
		};

		fetchDataAndUpdateState();
	}, []);

	useEffect(() => {
		if (mapRef.current && apiData.length > 0) {
			const bounds = new window.google.maps.LatLngBounds();
			apiData.forEach((item) => {
				if (item.chauffeur?.user_location) {
					bounds.extend(
						new window.google.maps.LatLng(
							parseFloat(item.chauffeur.user_location.latitude),
							parseFloat(item.chauffeur.user_location.longitude)
						)
					);
				}
			});

			// Add padding to the bounds
			const padding = { top: 50, right: 50, bottom: 50, left: 50 };
			mapRef.current.fitBounds(bounds, padding);
		}
	}, [apiData]);

	const handleMarkerClick = (marker) => {
		setSelectedMarker(marker);
		setIsModalOpen(true); // Open the modal on marker click
	};

	const handleModalClose = () => {
		setIsModalOpen(false); // Close the modal
		setSelectedMarker(null);
	};

	const navigate = useNavigate();

	const handleTrackClick = (rosterId) => {
		navigate(`/track/${rosterId}`);
	};

	return (
		<LoadScript googleMapsApiKey="AIzaSyAfQmP3pykh9oGR2CuiStJqplMZkVT1EKQ">
			{loading ? (
				<div>Loading...</div>
			) : (
				<GoogleMap
					mapContainerStyle={{ width: "100%", height: "100%" }}
					onLoad={(map) => {
						mapRef.current = map;
						const bounds = new window.google.maps.LatLngBounds();
						apiData.forEach((item) => {
							if (item.chauffeur?.user_location) {
								bounds.extend(
									new window.google.maps.LatLng(
										parseFloat(item.chauffeur.user_location.latitude),
										parseFloat(item.chauffeur.user_location.longitude)
									)
								);
							}
						});

						// Add padding to the bounds
						const padding = { top: 100, right: 50, bottom: 50, left: 50 };
						map.fitBounds(bounds, padding);
					}}
				>
					{apiData.map((item, index) => (
						<Marker
							key={index}
							position={{
								lat: parseFloat(item.chauffeur?.user_location?.latitude),
								lng: parseFloat(item.chauffeur?.user_location?.longitude),
							}}
							icon={{
								url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
							}}
							onClick={() => handleMarkerClick(item)}
						/>
					))}

					{/* Ant Design Drawer */}
					<Drawer
						title={
							selectedMarker
								? selectedMarker.chauffeur.name
								: "Ongoing Roster Details"
						}
						placement="right"
						closable={true}
						onClose={handleModalClose}
						open={isModalOpen}
						width={350} // Adjust width as needed
					>
						{selectedMarker && (
							<>
								<div style={{ display: "flex", flexDirection: "row" }}>
									<div>
										<img
											src={
												selectedMarker.chauffeur.profile_picture
													? selectedMarker.chauffeur.profile_picture
													: image
											} // Check for profile_picture, otherwise use fallback image
											alt="Profile"
											style={{
												height: "100px",
												width: "100px",
												borderRadius: "50%",
												marginTop: "10px",
												marginBottom: "10px",
											}}
										/>
									</div>
									<div>
										<Typography.Title level={5}>
											{selectedMarker.chauffeur.name}
										</Typography.Title>
										{/* <Typography.Text>
									Email: {selectedMarker.chauffeur.email_id}
								</Typography.Text>
								<br />
								<Typography.Text>
									Mobile: {selectedMarker.chauffeur.mobile}
								</Typography.Text> */}
										{/* <br /> */}
										{selectedMarker.chauffeur.vehicle && (
											<Typography.Text>
												License Plate:{" "}
												{selectedMarker.chauffeur.vehicle.license_plate}
											</Typography.Text>
										)}
										<br />
										<Typography.Text>
											Location Updated At:{" "}
											{moment(
												selectedMarker?.chauffeur?.user_location
													?.time_of_location
											).format("DD/MM/YYYY hh:mm A")}
										</Typography.Text>
										<br />
									</div>
								</div>

								{/* Loop over rider_roster_assignments to display each rider's details */}
								<Typography.Title level={5} style={{ marginTop: "20px" }}>
									Riders Information
								</Typography.Title>
								{selectedMarker.rider_roster_assignments.map(
									(assignment, index) => (
										<>
											<div key={index} style={{ marginBottom: "20px" }}>
												<Typography.Text>
													User {index + 1}: {assignment.rider.name}
												</Typography.Text>
												{/* <br />
											<Typography.Text>
												Email: {assignment.rider.email_id}
											</Typography.Text>
											<br />
											<Typography.Text>
												Mobile: {assignment.rider.mobile}
											</Typography.Text> */}
												<br />
												<Typography.Text>
													Status: {assignment.status}
												</Typography.Text>
												<br />
												<Typography.Text style={{ color: "green" }}>
													Pickup: {assignment.pickup_location}
												</Typography.Text>
												<br />
												<Typography.Text style={{ color: "red" }}>
													Drop Off: {assignment.drop_location}
												</Typography.Text>
												<br />
												<Typography.Text>
													Pickup Time:{" "}
													{moment(assignment.pickup_time).format(
														"DD/MM/YYYY hh:mm A"
													)}
												</Typography.Text>
												<br />
												<Typography.Text>
													Dropoff Time:{" "}
													{moment(assignment.dropoff_time).format(
														"DD/MM/YYYY hh:mm A"
													)}
												</Typography.Text>
											</div>
										</>
									)
								)}

								<div>
									<Button
										onClick={() => handleTrackClick(selectedMarker.id)}
									>
										Track
									</Button>
								</div>
							</>
						)}
					</Drawer>
				</GoogleMap>
			)}
		</LoadScript>
	);
};

export default OngoingRides;
