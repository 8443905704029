import React, { useState } from "react";
import { NavLink, redirect } from "react-router-dom";

import logo from "../../assets/logo.png";
import { ReactComponent as DashboardIcon } from "../../assets/🦆 icon _appstore.svg";
import { ReactComponent as UserIcon } from "../../assets/🦆 icon _user_.svg";
import { ReactComponent as ChauffeurIcon } from "../../assets/🦆 icon _user add_.svg";
import { ReactComponent as ScheduleIcon } from "../../assets/🦆 icon _date.svg";
import { ReactComponent as SubscriptionIcon } from "../../assets/🦆 icon _verification_.svg";
import { ReactComponent as MapIcon } from "../../assets/🦆 icon _map_.svg";
import { ReactComponent as BarChartIcon } from "../../assets/🦆 icon _bar chart_.svg";
import { ReactComponent as SubAdminIcon } from "../../assets/🦆 icon _plus circle_.svg";
import { ReactComponent as RouteOptimization } from "../../assets/🦆 icon _shopping cart_.svg";
import { ReactComponent as SettingIcon } from "../../assets/🦆 icon _setting_.svg";
import LogoutIcon from "../../assets/logout.svg";
import { BiSolidPhoneCall } from "react-icons/bi";
import SidebarMenu from "./SidebarMenu";
import "./sidebar.css";
import { AnimatePresence, motion } from "framer-motion";
import { deleteCookie } from "../utils/Utils";
import { FaMapPin } from "react-icons/fa";

interface Route {
  path: string;
  name: string;
  icon: JSX.Element;
  subRoutes?: Route[];
  exact?: boolean;
}

const routes2: Route[] = [
  {
    path: "/",
    name: "Dashboard",
    icon: <DashboardIcon />,
  },
  {
    path: "/users",
    name: "Users",
    icon: <UserIcon />,
  },
  {
    path: "/chauffeur",
    name: "Chauffeur",
    icon: <ChauffeurIcon />,
  },
  {
    path: "schedule-rides",
    name: "Schedule",
    icon: <ScheduleIcon />,
  },
  {
    path: "/map",
    name: "Maps",
    icon: <MapIcon />,
  },
  {
    path: "/onggoing-rides",
    name: "Ongoing Rides",
    icon: <FaMapPin />,
  },
  {
    path: "/reports",
    name: "Reports",
    icon: <BarChartIcon />,
  },
  // {
  //   path: "/history",
  //   name: "Subscription",
  //   icon: <SubscriptionIcon />,
  // },
  // {
  //   path: "sub-admin",
  //   name: "Create Sub Admin",
  //   icon: <SubAdminIcon />,
  // },
  // {
  //   path: "route-optimization",
  //   name: "Route Optimization",
  //   icon: <RouteOptimization />,
  // },
  // {
  //   path: "settings",
  //   name: "Settings",
  //   icon: <SettingIcon />,
  // },
  {
    path: "/sos",
    name: "SOS",
    icon: <BiSolidPhoneCall />,
  },
];

const routes1: Route[] = [
  {
    path: "/",
    name: "Dashboard",
    icon: <DashboardIcon />,
  },
  {
    path: "/users",
    name: "Users",
    icon: <UserIcon />,
  },
  {
    path: "/chauffeur",
    name: "Chauffeur",
    icon: <ChauffeurIcon />,
  },
  {
    path: "schedule-rides",
    name: "Schedule",
    icon: <ScheduleIcon />,
  },
  {
    path: "/map",
    name: "Maps",
    icon: <MapIcon />,
  },
  {
    path: "/reports",
    name: "Reports",
    icon: <BarChartIcon />,
  },
  {
    path: "/sos",
    name: "SOS",
    icon: <BiSolidPhoneCall />,
  },
  // {
  //   path: "/sos",
  //   name: "SOS",
  //   icon: <BiSolidPhoneCall />,
  // },
];

export interface SideBarProps {
  children: React.ReactNode;
  showSidebar: boolean;
}

const SideBar: React.FC<SideBarProps> = ({ children, showSidebar }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };
  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.9,
      },
    },
    show: {
      opacity: 1,

      width: "70%",
      transition: {
        duration: 0.9,
      },
    },
  };

  const handleLogout = () => {
    redirect("/login");
    deleteCookie("token_info");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("token_info");
    window.location.reload();
  };
  let hostname = window.location.hostname;
  const routes: Route[] =
    hostname === "www.gods-eye.in" || hostname === "localhost"
      ? routes2
      : routes1;

  console.log("isOpen_,", isOpen);
  console.log("showSidebar,", showSidebar);
  return (
    <>
      <div className="main-container">
        {showSidebar && (
          <div>
            <motion.div
              animate={{
                width: isOpen ? "255px" : "120px",
                transition: {
                  duration: 0.9,
                  type: "spring",
                  damping: 12,
                },
              }}
              className={`sidebar `}
            >
              <AnimatePresence>
                <div
                  className="top_section"
                  onClick={toggle}
                  style={{
                    padding: isOpen ? "30px 15px" : "30px 15px",
                    display: isOpen ? "flex" : "inline",
                  }}
                >
                  {
                    <motion.h1
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="logo-background"
                    >
                      <img src={logo} alt="logo" />
                    </motion.h1>
                  }
                  {isOpen && (
                    <div className="Heading">
                      <p style={{ fontSize: "15px", fontWeight: "800" }}>
                        Daftar Route
                      </p>
                      <p style={{ fontSize: "9px", fontWeight: "800" }}>
                        A Smart mobility to work
                      </p>
                    </div>
                  )}
                </div>
              </AnimatePresence>

              <section className="routes">
                {routes.map((route, index) => {
                  if (route.subRoutes) {
                    return (
                      <SidebarMenu
                        showAnimation={showAnimation}
                        setIsOpen={setIsOpen}
                        route={route}
                        isOpen={isOpen}
                        key={index}
                      />
                    );
                  }

                  return (
                    <NavLink to={route.path} key={index} className="link">
                      <div
                        className="icon"
                        style={{
                          marginLeft: route?.name == "Schedule" ? "5px" : "0px",
                        }}
                      >
                        {route.icon}
                      </div>

                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  );
                })}
              </section>
              <div className="logout-container">
                <img src={LogoutIcon} alt="logout" />
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="link_text"
                    >
                      <p className="link_text" onClick={handleLogout}>
                        Logout
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>
          </div>
        )}

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
