/** @format */
import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, Marker, Polyline, LoadScript } from "@react-google-maps/api";
import "antd/dist/antd.css"; // Ant Design CSS
import { useParams } from "react-router-dom";

const TrackRide = () => {
  const { rosterId } = useParams(); // Get roster ID from URL
  const [chauffeurLocation, setChauffeurLocation] = useState(null);
  const [destination, setDestination] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const token = localStorage.getItem("token_info");
        const response = await fetch(`https://api.gods-eye.in/api/admin/roster/trackRecord/${rosterId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        console.log(data,"data")
        if (data.roster) {
          setChauffeurLocation({
            lat: parseFloat(data.roster.chauffeur.user_location.latitude),
            lng: parseFloat(data.roster.chauffeur.user_location.longitude),
          });
          setDestination({
            lat: parseFloat(data.roster.destination_coordinates.latitude),
            lng: parseFloat(data.roster.destination_coordinates.longitude),
          });
        } else {
          console.error("Failed to fetch location data", data.error);
        }
      } catch (error) {
        console.error("Error fetching chauffeur location:", error);
      }
    };

    // Fetch initial location
    fetchLocationData();

    // Update chauffeur location every 30 seconds
    const intervalId = setInterval(fetchLocationData, 30000); // 30 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [rosterId]);

  return (
    <LoadScript googleMapsApiKey="AIzaSyAfQmP3pykh9oGR2CuiStJqplMZkVT1EKQ">
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100vh" }}
        zoom={12}
        center={chauffeurLocation || destination}
        onLoad={(map) => {
          mapRef.current = map;
        }}
      >
        {/* Chauffeur's Current Location (Green Marker) */}
        {chauffeurLocation && (
          <Marker
            position={chauffeurLocation}
            icon={{ url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png" }}
          />
        )}

        {/* Destination (Red Marker) */}
        {destination && (
          <Marker
            position={destination}
            icon={{ url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" }}
          />
        )}

        {/* Polyline between Chauffeur and Destination */}
        {chauffeurLocation && destination && (
          <Polyline
            path={[chauffeurLocation, destination]}
            options={{ strokeColor: "red", strokeOpacity: 1, strokeWeight: 2 }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default TrackRide;
