/** @format */

import React, { useState, useEffect, useRef } from "react";
import {
	GoogleMap,
	Marker,
	InfoWindow,
	LoadScript,
} from "@react-google-maps/api";
import moment from "moment";
import image from "../../assets/download (5).png";

const Map = () => {
	const mapRef = useRef(null);
	const [apiData, setApiData] = useState([]);
	const [selectedMarker, setSelectedMarker] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchDataAndUpdateState = async () => {
			try {
				const token = localStorage.getItem("token_info");
				const response = await fetch(
					"https://api.gods-eye.in/api/map/location/user",
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				const data = await response.json();
				if (data.success) {
					setApiData(data.data || []);
				} else {
					console.error("API request failed:", data.error);
				}
				setLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
				setLoading(false);
			}
		};

		fetchDataAndUpdateState();
	}, []);

	useEffect(() => {
		if (mapRef.current && apiData.length > 0) {
			const bounds = new window.google.maps.LatLngBounds();
			apiData.forEach((item) => {
				bounds.extend(
					new window.google.maps.LatLng(
						parseFloat(item.latitude),
						parseFloat(item.longitude)
					)
				);
			});

			// Add padding to the bounds
			const padding = { top: 50, right: 50, bottom: 50, left: 50 };
			mapRef.current.fitBounds(bounds, padding);
		}
	}, [apiData]);

	const handleMarkerClick = (marker) => {
		setSelectedMarker(marker);
	};

	const handleInfoWindowClose = () => {
		setSelectedMarker(null);
	};

	return (
		<LoadScript googleMapsApiKey="AIzaSyAfQmP3pykh9oGR2CuiStJqplMZkVT1EKQ">
			{loading ? (
				<div>Loading...</div>
			) : (
				<GoogleMap
					mapContainerStyle={{ width: "100%", height: "100%" }}
					onLoad={(map) => {
						mapRef.current = map;
						const bounds = new window.google.maps.LatLngBounds();
						apiData.forEach((item) => {
							bounds.extend(
								new window.google.maps.LatLng(
									parseFloat(item.latitude),
									parseFloat(item.longitude)
								)
							);
						});

						// Add padding to the bounds
						const padding = { top: 100, right: 50, bottom: 50, left: 50 };
						map.fitBounds(bounds, padding);
					}}
				>
					{apiData.map((item, index) => (
						<Marker
							key={index}
							position={{
								lat: parseFloat(item.latitude),
								lng: parseFloat(item.longitude),
							}}
							icon={{
								url:
									item.location_user.user_type === "CHAUFFEUR"
										? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
										: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
							}}
							onClick={() => handleMarkerClick(item)}
						/>
					))}
					{selectedMarker && (
						<InfoWindow
							position={{
								lat: parseFloat(selectedMarker.latitude),
								lng: parseFloat(selectedMarker.longitude),
							}}
							onCloseClick={handleInfoWindowClose}
						>
							<div>
								{selectedMarker.location_user.user_type === "CHAUFFEUR" && (
									<div style={{ display: "flex", flexDirection: "row" }}>
										<div>
											<img
												src={
													selectedMarker?.location_user?.profile_picture ||
													image
												} // Fallback to default image if profile_picture is not available
												style={{
													height: "100px",
													width: "100px",
													borderRadius: "200px",
													marginRight: "20px",
													marginTop: "10px",
												}}
												alt="Profile"
											/>
										</div>

										<div>
											<h3>{selectedMarker.location_user.name}</h3>
											<p>Email: {selectedMarker.location_user.email_id}</p>
											<p>User Type: {selectedMarker.location_user.user_type}</p>
											<p>
												License Plate:{" "}
												{selectedMarker.location_user.vehicle.license_plate}
											</p>
											<p>
												Location Updated At:{" "}
												{moment(selectedMarker?.updated_at).format(
													"DD/MM/YYYY hh:mm A"
												)}
											</p>
										</div>
									</div>
								)}
								{selectedMarker.location_user.user_type === "RIDER" && (
									<div style={{ display: "flex", flexDirection: "row" }}>
										<div>
											<img
												src={
													selectedMarker?.location_user?.profile_picture ||
													image
												} // Fallback to default image if profile_picture is not available
												style={{
													height: "100px",
													width: "100px",
													borderRadius: "200px",
													marginRight: "20px",
													marginTop: "10px",
												}}
												alt="Profile"
											/>
										</div>

										<div>
											<h3>{selectedMarker.location_user.name}</h3>
											<p>Email: {selectedMarker.location_user.email_id}</p>
											<p>User Type: {selectedMarker.location_user.user_type}</p>
										</div>
									</div>
								)}
							</div>
						</InfoWindow>
					)}
				</GoogleMap>
			)}
		</LoadScript>
	);
};

export default Map;
